import axios, { AxiosRequestConfig } from "axios";
import APIRoute from "../constants/APIRoute";
import EnvironmentVariable from "../constants/EnvironmentVariable";
import UserProfile from "../models/UserProfile";
import BaseAPIHelper from "./BaseAPIHelper";

export default class APIHelper extends BaseAPIHelper
{
    public static async GetUserTokenAsync(discordCode: string): Promise<UserProfile>
    {
        const route: string  = this.GetRoute(APIRoute.Auth_Login + discordCode);
        const userToken: any = await axios.get(route);

        return userToken.data;
    }

    public static async ValidateTokenAsync(apiToken: string): Promise<UserProfile>
    {
        const route: string = this.GetRoute(APIRoute.Auth_Validate);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        const userToken: any = await axios.get(route, params);

        // console.log("APIHelper - ValidateToken - userToken:", userToken.data);

        return new UserProfile(userToken.data);
    }
}