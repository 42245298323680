import { ButtonType } from '../enums/ButtonType';
import { ButtonIcon } from '../enums/ButtonIcon';
import './ButtonComponent.scss';

// icons
import discord_logo from '../assets/discord.svg';
import plus_icon from '../assets/bx-plus.svg';
import trash_icon from '../assets/bx-trash.svg';
import edit_icon from '../assets/bx-edit-alt.svg';

interface IButtonProps
{
    text?: string;
    maxCharactersCount?: number;
    icon?: ButtonIcon;
    type?: ButtonType;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function Button(props: IButtonProps)
{
    return <>
    <button
        className={ GetButtonStyle(props.type) }
        onClick={ props.onClick }
    >
        { GetButtonContent(props.icon, props.text, props.maxCharactersCount) }
    </button>
    </>
}

function GetButtonStyle(type?: ButtonType)
{
    let result = "bh-button ";

    switch (type)
    {
        case ButtonType.WARNING:
            result += "bh-button-warning";
            break;

        case ButtonType.DANGER:
            result += "bh-button-danger";
            break;

        case ButtonType.DISABLED:
            result += "bh-button-disabled";
            break;

        case ButtonType.GOOD:
            result += "bh-button-good";
            break;

        default:
        case ButtonType.DEFAULT:
            result += "bh-button-primary";
            break;
    }

    return result;
}

function GetButtonContent(icon?: ButtonIcon, text?: string, maxCharactersCount?: number)
{
    let iconResult = "";

    switch(icon)
    {
        case ButtonIcon.DISCORD:
            iconResult = discord_logo;
            break;

        case ButtonIcon.PLUS:
            iconResult = plus_icon;
            break;

        case ButtonIcon.TRASH:
            iconResult = trash_icon;
            break;

        case ButtonIcon.EDIT:
            iconResult = edit_icon;
            break;
    }

    if (!text)
        return <><img src={iconResult} /></>

    if (maxCharactersCount && text.length > maxCharactersCount)
    {
        text = text.substring(0, maxCharactersCount) + '...';
    }

    return <>
        <img src={iconResult} /> <span className={!icon ? '' : 'textWithImage'}>{text}</span>
    </>
}