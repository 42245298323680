import localforage from "localforage";
import StorageKey from "../constants/StorageKey";
import UserProfile from "../models/UserProfile";

export default class SessionHelper
{
    public static async SaveUserProfile(userProfile: UserProfile): Promise<void>
    {
        await localforage.setItem(StorageKey.UserToken, userProfile);
    }

    public static async GetTokenAsync(): Promise<string>
    {
        const userProfile: UserProfile | null = await localforage.getItem(StorageKey.UserToken);

        if (!userProfile)
            throw "No user profile saved in the browser.";

        return userProfile.token ?? "";
    }

    public static async ClearUserProfile(): Promise<void>
    {
        await localforage.removeItem(StorageKey.UserToken);
    }
}