import { AxiosRequestConfig } from "axios";
import EnvironmentVariable from "../constants/EnvironmentVariable";
import SessionHelper from "./SessionHelper";

export default abstract class BaseAPIHelper
{
    protected static async GetAuthorizationHeadersAsync(): Promise<AxiosRequestConfig>
    {
        const userToken: string = await SessionHelper.GetTokenAsync();

        return {
            headers:
            {
                "Authorization": `Bearer ${userToken}`
            }
        };
    }

    /**
     * Get the full route to the api
     * @param route Route from APIRoute class
     */
    protected static GetRoute(route: string): string
    {
        return process.env[EnvironmentVariable.APIServer] + route;
    }
}