export default class SiteRoute
{
    /** "/" */
    public static readonly Index: string = "/";
    /** "/about" */
    public static readonly About: string = "/about";
    /** "/login" */
    public static readonly Login: string = "/login";
    /** "/profile" */
    public static readonly Member: string = "/profile";

    // Bunny Inside
    /** "/inside" */
    public static readonly BunnyMemberSection: string = "/inside";
    /** "/inside/index" */
    public static readonly IndexBunnyMember: string = "/inside/index";
    /** "/inside/eventcalendar" */
    public static readonly EventCalendar: string = "/inside/eventcalendar";

    // Bunny Inside - Admin
    /**  "/inside/admin" */
    public static readonly AdminSection: string = "/inside/admin";
    /**  "/inside/admin/testCreateMessage" */
    public static readonly AdminTestCreateMessage: string = "/inside/admin/testCreateMessage";
    /**  "/inside/admin/reactionsMessages" */
    public static readonly AdminReactionsMessages: string = "/inside/admin/reactionsMessages";
    /**  "/inside/admin/reactionsMessage" */
    public static readonly AdminReactionsMessage: string = "/inside/admin/reactionsMessage";

    // hidden
    /** "/az7s1x1223" */
    public static readonly Test: string = "/az7s1x1223";
}