import Picker from '@emoji-mart/react';
import EmojiFromPicker from '../models/components/EmojiFromPicker';

export default function EmojiPicker()
{
    return (
    <>
        <Picker
            set="twitter"
            onEmojiSelect={(selectedEmoji: EmojiFromPicker) =>
            {
                // console.log("selectedEmoji:", selectedEmoji);
            }}
        />
    </>);
}