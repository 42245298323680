import axios, { AxiosRequestConfig } from "axios";
import APIRoute from "../constants/APIRoute";
import EnvironmentVariable from "../constants/EnvironmentVariable";
import DiscordChannel from "../models/API/DiscordChannel";
import BaseAPIHelper from "./BaseAPIHelper";

export default class APIGuildHelper extends BaseAPIHelper
{
    public static async GetChannelsAsync(): Promise<DiscordChannel[]>
    {
        const route: string = this.GetRoute(APIRoute.Guild_GetChannels);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        const apiChannels: any = await axios.get(route, params);

        // console.log("GetChannels, result:", apiChannels);

        return apiChannels.data;
    }
}