import '../css/Global.scss';

import localforage from 'localforage';
import { Link } from 'react-router-dom';
import question_mark from '../assets/bx-question-mark.svg';
import LinkCard from '../components/LinkCardComponent';
import { Dispatch, SetStateAction, useContext, useEffect, useReducer, useState } from 'react';
// import axios from 'axios';
import StorageKey from '../constants/StorageKey';
import UserProfile from '../models/UserProfile';
import { AuthContext, AuthContextProps } from '../services/AuthContext';

// async function TestLocalForage(setTestLocalForage: Dispatch<string>)
// {
//     let testVariable: string|null = await localforage.getItem<string>(StorageKey.TestVariable);

//     if (testVariable && testVariable !== "EMPTY")
//     {
//         testVariable = "EMPTY";
//         await localforage.setItem("testVariable", testVariable);
//         setTestLocalForage(testVariable);
//     }
//     else
//     {
//         testVariable = new Date().toString();
//         await localforage.setItem("testVariable", testVariable);
//         setTestLocalForage(testVariable);
//     }
// }

// async function TestAxios()
// {
//     const response = await axios.get("https://localhost:7133/api/Test/GetTestDb/GetAllUsers");

//     console.log("Get all users test:", response);
// }

// async function TestReadUrlParameter(searchParams: URLSearchParams)
// {
//     console.log("TestReadUrlParameter");
//     console.log("searchParams", searchParams);
//     console.log("searchParams -> code", searchParams.get("code"));
// }

async function TestReadUserToken()
{
    const userToken: string|null = await localforage.getItem<string>(StorageKey.UserToken);

    // console.log("AboutPage - TestReadUserToken - UserToken: ", userToken);
}

export default function AboutPage()
{
    const authContext: AuthContextProps = useContext(AuthContext);
    const userProfile: UserProfile = authContext.userProfile;

    // Check if we have a valid user
    const validUserProfile: boolean =
        userProfile !== undefined && userProfile.id !== undefined;

    // console.log("AboutPage - userProfile:", userProfile);
    // console.log("AboutPage - validUserProfile: ", validUserProfile);
    // const [testLocalForage, setTestLocalForage] = useState("test");
    // const [searchParams, setSearchParams] = useSearchParams();
    // const navigate = useNavigate();

    useEffect(() => {
        // console.log("AboutPage - useEffect");

        // updateUserProfile(userProfile);

        // TestLocalForage(setTestLocalForage);
        // TestAxios();
        // TestReadUrlParameter(searchParams);
        TestReadUserToken();

    }, [userProfile]); // <= empty array = useEffect will only run ONCE

    function OnClick_Profile()
    {
        userProfile.username = new Date().toString();

        // updateUserProfile((previousState: UserProfile) => ({
        //     ...previousState,
        //     userProfile
        // }));

        // console.log("OnClick_Profile - userProfile.Username:", userProfile.username);

        authContext.updateUserProfile(userProfile);
    }

    return (
    <>
        <p>About page TODO</p>
        <div onClick={OnClick_Profile}>click me uwu</div>
        <div>User profile: {userProfile.username}</div>
        <Link to="/"><LinkCard image={question_mark} title="Home" /></Link>
        {/* <p>Local forage test: { testLocalForage }</p> */}
    </>);
}