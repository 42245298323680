import { DiscordMessage, DiscordMessages } from "@danktuary/react-discord-message";
import HTMLReactParser from "html-react-parser";
import discoBunAvatar from "../assets/discobun.png";

interface IDiscordPreviewComponentProps
{
    message: string;
}

export default function DiscordPreview(
    props: IDiscordPreviewComponentProps)
{
        // console.log("DiscordPreview: props:", props);

    return (
    <>
        <DiscordMessages>
            <DiscordMessage
                author="DiscoBun"
                avatar={discoBunAvatar}
                roleColor="#0099ff"
                bot={true}
            >
                { HTMLReactParser(props.message) }
            </DiscordMessage>
        </DiscordMessages>
    </>)
}