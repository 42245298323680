import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";

import SiteRoute     from "../constants/SiteRoute";
import URLParameter  from "../constants/URLParameter";
import APIHelper     from "../helpers/APIHelper";
import SessionHelper from "../helpers/SessionHelper";
import UserProfile   from "../models/UserProfile";
import { AuthContext, AuthContextProps } from "../services/AuthContext";

async function APILogin(
    searchParams: URLSearchParams,
    navigate: NavigateFunction,
    userProfile: UserProfile,
    updateUserProfile: Function)
{
    // console.log("APILogin - Start");

    // Get code
    // TODO Check if code is not null
    const code: string = searchParams.get(URLParameter.Code) || "";

    // Call Bunny API to get user token
    //userProfile = await APIHelper.GetUserToken(code);
    let newUserProfile = await APIHelper.GetUserTokenAsync(code);
    // console.log("APILogin - newUserProfile:", newUserProfile);

    // updateUserProfile(newUserProfile);

    // Save user token to local storage
    // await localforage.setItem(StorageKey.UserToken, userProfile);
    await SessionHelper.SaveUserProfile(newUserProfile);

    updateUserProfile(newUserProfile);

    // console.log("APILogin - updated user profile:", newUserProfile);

    // Redirect to main page
    navigate(SiteRoute.Index, { replace: true });
}

export default function LoginPage()
{
    // Props
    const authContext: AuthContextProps = useContext(AuthContext);
    const userProfile: UserProfile = authContext.userProfile;

    // Internal states
    const [loginState, setLoginState]     = useState("Connecting...");
    const [searchParams, setSearchParams] = useSearchParams();

    // Libs
    const navigate: NavigateFunction = useNavigate();

    useEffect(() =>
    {
        APILogin(searchParams, navigate, userProfile, authContext.updateUserProfile);
    }, []); // [] = This code will only run once

    return <>
        <h1>Login page</h1>
        <p>State: {loginState} </p>
    </>;
}