import axios, { AxiosRequestConfig } from "axios";
import APIRoute from "../constants/APIRoute";
import EnvironmentVariable from "../constants/EnvironmentVariable";
import BaseAPIHelper from "./BaseAPIHelper";

export default class APIEventHelper extends BaseAPIHelper
{
    public static async GetCalendarAsync(): Promise<any>
    {
        const route: string = this.GetRoute(APIRoute.Event_GenerateEventCalendar);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        // Calendar is a PNG image as byte array
        params.responseType = "blob";

        const calendar: any = await axios.post(route, {}, params);

        // console.log("GetCalendar, result:", calendar);

        return calendar.data;
    }
}