import { Link } from "react-router-dom";
import LinkCard from "./LinkCardComponent";
import icon_home from "../assets/bx-undo.svg";
import "./BunnyHomeButtonComponent.scss";
import SiteRoute from "../constants/SiteRoute";

export default function BunnyHomeButton()
{
    return (
    <div className="bunny-home-button">
        <Link to={SiteRoute.IndexBunnyMember}>
            <LinkCard image={icon_home} title="Bunny home" />
        </Link>
    </div>
    );
}