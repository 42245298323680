import axios, { AxiosRequestConfig } from "axios";
import APIRoute from "../constants/APIRoute";
import ReactionsMessage from "../models/API/ReactionsMessages/ReactionsMessage";
import DiscordRole from "../models/API/Roles/DiscordRole";
import BaseAPIHelper from "./BaseAPIHelper";

export default class APIRolesHelper extends BaseAPIHelper
{
    public static async GetRolesAsync(): Promise<DiscordRole[]>
    {
        const route: string = this.GetRoute(APIRoute.Roles_Get);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        const roles: any = await axios.get(route, params);

        // console.log("roles, result:", roles);

        return roles.data;
    }
}