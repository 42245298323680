import './IndexPage.scss';
import '../css/Global.scss';

import logo          from '../assets/bunny.svg';
import discord_logo  from '../assets/discord.svg';
import enlist_logo   from '../assets/bxs-news.svg';
import wiki_logo     from '../assets/bxl-wikipedia.svg';
import inside_logo   from '../assets/bxs-door-open.svg';
import LinkCard      from '../components/LinkCardComponent';
import { Link }      from 'react-router-dom';
import config        from '../config.json';
import { useContext, useEffect, useState } from 'react';
import UserProfile   from '../models/UserProfile';
import SiteRoute     from '../constants/SiteRoute';
import { AuthContext, AuthContextProps } from '../services/AuthContext';

function ShowDebug()
{
    if (process.env.NODE_ENV === 'development')
    {
        return <>
        <pre>
            DEBUG <br/>
            ----- <br/>
            config.Test:       {config.Test} <br/>
            NODE_ENV:          {process.env.NODE_ENV}<br/>
            test_env_variable: {process.env.REACT_APP_TEST_ENV_VARIABLE} <br/>
        </pre>
        </>
    }
}

export default function IndexPage()
{
    const authContext: AuthContextProps = useContext(AuthContext);
    const userProfile: UserProfile = authContext.userProfile;

    useEffect(() => {
        // console.log("IndexPage - useEffect");
        // console.log("IndexPage - useEffect - userProfile:", userProfile);

    }, [userProfile]); // <= empty array = useEffect will only run once

    return <>
    <div className="global-default-page-container index-page">
        <div className="logo-container">
            <img src={logo} className="logo-image" alt="Bunny Hope logo" />
            <h1 className="logo-title">Bunny Hope</h1>
        </div>
        <p>
            Welcome to Bunny Hope! 🐇<br />
            Please click on one of the following links to access one of our subsite.
        </p>
        <div className="app-links">
            <LinkCard image={enlist_logo} title="Recruitment" url="https://recruit.bunnyhope.cafe" />
            <LinkCard image={discord_logo} title="Discord" url="https://discord.bunnyhope.cafe" />
            <LinkCard image={wiki_logo} title="Wiki" url="https://wiki.bunnyhope.cafe" />
            {
                IsBunnyMember() &&
                <Link className="global-noAnchorDecoration" to={SiteRoute.IndexBunnyMember}>
                    <LinkCard image={inside_logo} title="Bunny Access" />
                </Link>
            }
        </div>
    </div>

    {/* { ShowDebug() } */}
    </>

    function IsBunnyMember(): boolean
    {
        const canCallFunction = userProfile && userProfile?.IsBunnyMember;

        if (!canCallFunction)
            return false;

        return userProfile.IsBunnyMember();
    }
}