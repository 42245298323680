import { BunnyLevel } from "../enums/BunnyLevel";

export default class UserProfile
{
    public id:            string     | undefined;
    public username:      string     | undefined = "Anonymous";
    public avatar:        string     | undefined;
    public discriminator: string     | undefined;
    public publicFlags:   number     | undefined;
    public flags:         number     | undefined;
    public banner:        string     | undefined;
    public bannerColor:   string     | undefined;
    public accentColor:   string     | undefined;
    public locale:        string     | undefined;
    public mfaEnabled:    boolean    | undefined;
    public premiumType:   number     | undefined;
    public email:         string     | undefined;
    public verified:      boolean    | undefined;
    public fullUsername:  string     | undefined;
    public token:         string     | undefined;
    public bunnyLevel:    BunnyLevel | undefined;

    private _bunnyLevelTitle: string | undefined;

    public get bunnyLevelTitle(): string
    {
        if (this.bunnyLevel === undefined)
            return '';

        if (this._bunnyLevelTitle === undefined)
        {
            this._bunnyLevelTitle = BunnyLevel[this.bunnyLevel];
        }

        return this._bunnyLevelTitle;
    }

    constructor(newObject?: UserProfile)
    {
        this.id            = newObject?.id;
        this.username      = newObject?.username;
        this.avatar        = newObject?.avatar;
        this.discriminator = newObject?.discriminator;
        this.publicFlags   = newObject?.publicFlags;
        this.flags         = newObject?.flags;
        this.banner        = newObject?.banner;
        this.bannerColor   = newObject?.bannerColor;
        this.accentColor   = newObject?.accentColor;
        this.locale        = newObject?.locale;
        this.mfaEnabled    = newObject?.mfaEnabled;
        this.premiumType   = newObject?.premiumType;
        this.email         = newObject?.email;
        this.verified      = newObject?.verified;
        this.fullUsername  = newObject?.fullUsername;
        this.token         = newObject?.token;
        this.bunnyLevel    = newObject?.bunnyLevel;
    }

    public IsBunnyMember(): boolean
    {
        return  this.bunnyLevel === BunnyLevel.BunnyMember   ||
                this.bunnyLevel === BunnyLevel.BunnyAnimator ||
                this.bunnyLevel === BunnyLevel.BunnyAdmin;
    }

    public IsBunnyAnimator(): boolean
    {
        return  this.bunnyLevel === BunnyLevel.BunnyAnimator ||
                this.bunnyLevel === BunnyLevel.BunnyAdmin;
    }

    public IsBunnyAdmin(): boolean
    {
        return this.bunnyLevel == BunnyLevel.BunnyAdmin;
    }

    public HasAccess(bunnyLevel: BunnyLevel): boolean
    {
        switch (bunnyLevel)
        {
            case BunnyLevel.Unknown:
            default:
                throw "UserProfile - HasAccess => Unknown level given";

            case BunnyLevel.BunnyAdmin:
                return this.IsBunnyAdmin();

            case BunnyLevel.BunnyAnimator:
                return this.IsBunnyAnimator();

            case BunnyLevel.BunnyMember:
                return this.IsBunnyMember();

            case BunnyLevel.Guest:
                return true;
        }
    }
}