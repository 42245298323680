import React, { useContext } from "react";
import Button from "../../components/ButtonComponent";
import HomeButton from "../../components/HomeButtonComponent";
import { ButtonIcon } from "../../enums/ButtonIcon";
import { ButtonType } from "../../enums/ButtonType";
import UserProfile from "../../models/UserProfile";
import { AuthContext, AuthContextProps } from "../../services/AuthContext";
import Picker from '@emoji-mart/react';
import EmojiPicker from "../../components/EmojiPickerComponent";

export default function TestPage()
{
    const authContext: AuthContextProps = useContext(AuthContext);
    const userProfile: UserProfile = authContext.userProfile;

    return <>
        <h1>Test context</h1>

        <EmojiPicker />

        <p>{ userProfile.fullUsername }</p>
        <h1>H1 Title</h1>
        <p>Lorem ipsum dolor sit amet. Sed magnam excepturi qui sapiente dictaad eveniet ad corrupti enim. Ut esse cumque vel reprehenderit reprehenderit Ex quis.</p>
        <h2>H2 Title</h2>
        <p>Lorem ipsum dolor sit amet. Sed magnam excepturi qui sapiente dictaad eveniet ad corrupti enim. Ut esse cumque vel reprehenderit reprehenderit Ex quis.</p>
        <h3>H3 Title</h3>
        <p>Lorem ipsum dolor sit amet. Sed magnam excepturi qui sapiente dictaad eveniet ad corrupti enim. Ut esse cumque vel reprehenderit reprehenderit Ex quis.</p>
        <h4>H4 Title</h4>
        <p>Lorem ipsum dolor sit amet. Sed magnam excepturi qui sapiente dictaad eveniet ad corrupti enim. Ut esse cumque vel reprehenderit reprehenderit Ex quis.</p>

        <Button
            text="Log in with Discord"
            icon={ButtonIcon.DISCORD} />

        <Button
            text="Button icon UNKNOWN"
            icon={ButtonIcon.UNKNOWN} />

        <div>
            <Button
                icon={ButtonIcon.DISCORD} />

            <Button
                icon={ButtonIcon.EDIT} />

            <Button
                icon={ButtonIcon.TRASH} />

            <Button
                icon={ButtonIcon.PLUS} />
        </div>



        <Button
            text="Default button style" />

        <Button
            text="GOOD button style"
            type={ButtonType.GOOD}
            icon={ButtonIcon.PLUS} />

        <Button
            text="WARNING button style"
            type={ButtonType.WARNING} />

        <Button
            text="DANGER button style"
            icon={ButtonIcon.DISCORD}
            type={ButtonType.DANGER} />

        <Button
            text="DISABLED button style"
            type={ButtonType.DISABLED} />

        <HomeButton />
    </>
}