import { useContext, useEffect } from "react";
import { NavigateFunction, useNavigate, useOutlet } from "react-router-dom";
import SiteRoute from "../constants/SiteRoute";
import { BunnyLevel } from "../enums/BunnyLevel";
import APIHelper from "../helpers/APIHelper";
import UserProfile from "../models/UserProfile";
import { AuthContextProps, AuthContext } from "../services/AuthContext";

async function ValidateUserProfile(
    apiToken: string | undefined): Promise<UserProfile>
{
    let result: UserProfile = new UserProfile();

    try
    {
        if (!apiToken)
            return result;

        result = await APIHelper.ValidateTokenAsync(apiToken);
    }
    catch (error: unknown)
    {
        console.error("ProtectedRoute - ValidateUserProfile - Error:", error);
    }
    finally
    {
        return result;
    }
}

interface IProtectedRouteProps
{
    requiredLevel: BunnyLevel;
}

export default function ProtectedRoute(props: IProtectedRouteProps)
{
    const authContext: AuthContextProps = useContext(AuthContext);
    const userProfile: UserProfile      = authContext.userProfile;
    const navigate: NavigateFunction    = useNavigate();
    const outlet                        = useOutlet();

    const requiredLevel: BunnyLevel = props.requiredLevel;

    useEffect(() =>
    {
        // console.log("ProtectedRoute - userProfile", userProfile, "requiredLevel:", requiredLevel);

        ValidateUserProfile(userProfile.token)
            .then((updatedUserProfile: UserProfile) =>
            {
                authContext.updateUserProfile(userProfile);

                if (!updatedUserProfile.HasAccess(requiredLevel))
                    navigate(SiteRoute.Index, { replace: true });
            });
    });

    return (
    <>
        { outlet }
    </>);
}