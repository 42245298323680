import axios, { AxiosRequestConfig } from "axios";
import APIRoute from "../constants/APIRoute";
import ReactionsMessage from "../models/API/ReactionsMessages/ReactionsMessage";
import BaseAPIHelper from "./BaseAPIHelper";

export default class APIReactionsMessageHelper extends BaseAPIHelper
{
    public static async GetReactionsMessagesAsync(): Promise<ReactionsMessage[]>
    {
        const route: string = this.GetRoute(APIRoute.ReactionsMessages_Get);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        const reactionsMessages: any = await axios.get(route, params);

        // console.log("reactionsMessages, result:", reactionsMessages);

        return reactionsMessages.data;
    }

    public static async GetReactionsMessageAsync(messageId: string): Promise<ReactionsMessage>
    {
        const route: string = this.GetRoute(`${APIRoute.ReactionsMessages_Get}/${messageId}`);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        const reactionsMessage: any = await axios.get(route, params);

        // console.log("reactionsMessages, result:", reactionsMessage);

        return reactionsMessage.data;
    }
}