import "./IndexBunnyMemberPage.scss";
import "../../css/Global.scss";

import UserProfile from "../../models/UserProfile";
import HomeButton from "../../components/HomeButtonComponent";

import calendar_logo from "../../assets/bx-calendar.svg";
import edit_logo from "../../assets/bx-edit-alt.svg";
import add_logo from "../../assets/bx-list-plus.svg";

import LinkCard from "../../components/LinkCardComponent";
import SiteRoute from "../../constants/SiteRoute";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext, AuthContextProps } from "../../services/AuthContext";

function ShowAdminSection(userProfile: UserProfile)
{
    if (!userProfile.IsBunnyAdmin())
        return <></>;

    return (
    <>
        <h1>👑 Admin section</h1>
        <div className="links-container">
            <Link to={SiteRoute.AdminReactionsMessages} className="global-noAnchorDecoration">
                <LinkCard image={calendar_logo} title="Reactions messages" />
            </Link>
            <Link to={SiteRoute.AdminTestCreateMessage} className="global-noAnchorDecoration">
                <LinkCard image={calendar_logo} title="TestCreateMessage" />
            </Link>
            <Link to={SiteRoute.Test} className="global-noAnchorDecoration">
                <LinkCard image={calendar_logo} title="TEST" />
            </Link>
            <Link to={SiteRoute.About} className="global-noAnchorDecoration">
                <LinkCard image={calendar_logo} title="ABOUT" />
            </Link>
        </div>
    </>
    );
}

export default function IndexBunnyMemberPage()
{
    const authContext: AuthContextProps = useContext(AuthContext);

    useEffect(() =>
    {
        // console.log("IndexBunnyMember - useEffect");
    }, []);

    return <>
        <div className="global-default-page-container index-bunny-member">
            <h1>🐰 Inside Bunny</h1>
            <h2>🗓️ Events</h2>
            <div className="links-container">
                <LinkCard image={add_logo} title="Create a new event" url="https://recruit.bunnyhope.cafe" />
                <LinkCard image={edit_logo} title="Manage events" url="https://discord.bunnyhope.cafe" />
                <Link to={SiteRoute.EventCalendar} className="global-noAnchorDecoration">
                    <LinkCard image={calendar_logo} title="Event calendar" />
                </Link>
            </div>

            { ShowAdminSection(authContext.userProfile) }

            {/* <h2>Rules</h2> */}
            {/* <p>
                Todo:<br/>
                <ul>
                    <li>Create an event</li>
                    <li>Edit an event</li>
                    <li>Delete an event</li>
                    <li>See all events</li>
                    <li>Events calendar</li>
                    <li>Manage server rules</li>
                </ul>
            </p> */}

            <HomeButton />
        </div>
    </>
}