import './EventCalendarPage.scss';
import { useEffect, useState } from "react";
import APIEventHelper from "../../helpers/APIEventHelper";
import HomeButton from '../../components/HomeButtonComponent';
import BunnyHomeButton from '../../components/BunnyHomeButtonComponent';

interface IEventCalendarPageProps
{

}

// TODO Types!
export default function EventCalendarPage(props: IEventCalendarPageProps)
{
    // console.log('EventCalendarPage - Start');

    const [eventCalendarImage, setEventCalendarImage] = useState();

    useEffect(() =>
    {
        GetCalendarAsync();
    }, []); // Will only run once

    return <>
    <div className="global-default-page-container event-calendar-page">
        <h1>🗓️ Event calendar</h1>
        {
            eventCalendarImage && ShowCalendar(eventCalendarImage)
        }
    </div>
    </>

    async function GetCalendarAsync()
    {
        // console.log('EventCalendarPage - GetCalendar - IN');

        const latestCalendar = await APIEventHelper.GetCalendarAsync();
        // console.log('EventCalendarPage - GetCalendar - latestCalendar:', latestCalendar);
        // console.log('EventCalendarPage - GetCalendar - OUT');

        setEventCalendarImage(latestCalendar);
    }

    function ShowCalendar(eventCalendarImage: any)
    {
        const objectURL: string = URL.createObjectURL(eventCalendarImage);

        return <>
        <a href={objectURL} target="_blank">
            <img className="calendar" src={objectURL} />
        </a>

        <BunnyHomeButton />
        </>
    }
}