import './LinkCardComponent.scss';

export interface LinkCardProps {
    image: string;
    title: string;
    url?: string;
}

function GetCardContent(image: string, title: string)
{
    return(
        <>
        <img src={image} alt={title} />
        <b>{title}</b>
        </>
    )
}

export default function LinkCard({ image, title, url } : LinkCardProps)
{
    if (url)
    {
        return (
            <a className="link-card-container" href={url}>
                {GetCardContent(image, title)}
            </a>
        );
    }

    return (
        <div className="link-card-container">
            {GetCardContent(image, title)}
        </div>
    );
}