import { useContext, useEffect, useState } from "react";
import BunnyHomeButton from "../../../components/BunnyHomeButtonComponent";
import DiscordChannel from "../../../models/API/DiscordChannel";
import { AuthContext, AuthContextProps } from "../../../services/AuthContext";
import { useForm } from "react-hook-form";
import APIGuildHelper from "../../../helpers/APIGuildHelper";
import SimpleMessage from "../../../models/API/Job/SimpleMessage";
import APIJobHelper from "../../../helpers/APIJobHelper";
import Button from "../../../components/ButtonComponent";
import { ButtonType } from "../../../enums/ButtonType";
import DiscordPreview from "../../../components/DiscordPreviewComponent";

async function GetDiscordChannelsAsync(): Promise<JSX.Element[]>
{
    const channels: DiscordChannel[] = await APIGuildHelper.GetChannelsAsync();

    let mappedList: JSX.Element[] = channels.map((item) =>
    {
        return <option key={item.id} value={item.id}>{item.name}</option>;
    });

    mappedList.unshift(<option key="" value="">Select a channel...</option>);

    return mappedList;
}

async function CreateNewMessage(formData: SimpleMessage)
{
    if (!window.confirm("Are you sure you wanna submit this message?"))
        return;

    // console.log("Form submit:", formData);
    // console.log("ChannelID:", formData.channelId);
    // console.log("Content:", formData.content);

    let errors = "";

    let hasChannelID: boolean = formData.channelId != null && formData.channelId.length > 0;
    let hasContent: boolean = formData.content != null && formData.content.length > 0;

    if (!hasChannelID)
        errors += "\n- Please select a channel";

    if (!hasContent)
        errors += "\n- Please enter a message";

    if (errors.length > 0)
    {
        errors = "The form has some errors:\n" + errors;

        alert(errors);

        return;
    }

    await APIJobHelper.CreateNewSimpleMessageAsync(formData);
}

export default function AdminTestCreateMessagePage()
{
    const authContext: AuthContextProps = useContext(AuthContext);
    const [channelsOptions, setChannelsOptions]: any = useState();
    const [previewMessage, setPreviewMessage] = useState("");

    const { register, handleSubmit, watch, formState: { errors }} = useForm();
    const onFormSubmit = (formData: any) => { CreateNewMessage(formData); }

    useEffect(() =>
    {
        GetDiscordChannelsAsync()
            .then((channels => setChannelsOptions(channels)));
    }, []);

    return <>
        <div className="global-default-page-container admin-test-create-message">
            <h1>Test: Create a new message</h1>
            <p>
                This test will try to create a new message on a selected channel through the 🐰 DiscoBun bot.
            </p>

            <form id="adminTestCreateMessageForm" onSubmit={handleSubmit(onFormSubmit)}>
                <label htmlFor="channelId">Select a channel:</label>
                <select
                    id="channelId"
                    {
                        ...register("channelId")
                }>
                    { channelsOptions }
                </select>

                <label htmlFor="content">Enter the message to send:</label>
                <textarea
                    id="content"
                    {
                        ...register("content")
                    }

                    onChange=
                    {
                        (event: React.ChangeEvent<HTMLTextAreaElement>) =>
                        {
                            let message = event.target.value;
                            message = message.replaceAll('\n', '<br />');

                            setPreviewMessage(message);
                        }
                    }
                />

                { errors.exampleRequired && <p>This field is required</p> }

            </form>

            <Button
                text="Submit"
                type={ ButtonType.DEFAULT }
                onClick={ handleSubmit((data: any) => { CreateNewMessage(data as SimpleMessage) }) }
            />

        </div>

        <h2>Preview test</h2>
        <DiscordPreview message={previewMessage} />

        <BunnyHomeButton />
    </>
}