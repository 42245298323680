import { useContext, useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";
import BunnyHomeButton from "../../../components/BunnyHomeButtonComponent";
import { AuthContext, AuthContextProps } from "../../../services/AuthContext";
import { useForm } from "react-hook-form";
import Button from "../../../components/ButtonComponent";
import { ButtonType } from "../../../enums/ButtonType";
import APIReactionsMessageHelper from "../../../helpers/APIReactionsMessageHelper";
import DiscordChannel from "../../../models/API/DiscordChannel";
import APIGuildHelper from "../../../helpers/APIGuildHelper";
import DiscordPreview from "../../../components/DiscordPreviewComponent";
import EmojiPicker from "../../../components/EmojiPickerComponent";
import { ButtonIcon } from "../../../enums/ButtonIcon";
import DiscordRole from "../../../models/API/Roles/DiscordRole";
import APIRolesHelper from "../../../helpers/APIRolesHelper";

async function GetDiscordChannelsAsync(): Promise<JSX.Element[]>
{
    const channels: DiscordChannel[] = await APIGuildHelper.GetChannelsAsync();

    let mappedList: JSX.Element[] = channels.map((item) =>
    {
        return <option key={item.id} value={item.id}>{item.name}</option>;
    });

    mappedList.unshift(<option key="" value="">Select a channel...</option>);

    return mappedList;
}

async function GetRolesAsync(): Promise<DiscordRole[]>
{
    const roles: DiscordRole[] = await APIRolesHelper.GetRolesAsync();

    // console.log("GetRolesAsync:", roles);

    return roles;
}

export default function AdminReactionsMessagePage()
{
    const
    {
        setValue,
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const authContext: AuthContextProps = useContext(AuthContext);

    let params: Params<string>  = useParams();

    const [debug, setDebug] = useState("");
    const [pageTitle, setPageTitle] = useState("");
    const [isNewMessage] = useState(!params.messageId);
    const [channelsOptions, setChannelsOptions]: any = useState();
    const [previewMessage, setPreviewMessage] = useState("");

    async function LoadReactionsMessageAsync()
    {
        let messageId: string = params.messageId || '';

        let reactionMessage =
            await APIReactionsMessageHelper.GetReactionsMessageAsync(messageId);

        setValue('title', reactionMessage.title);
        setValue('message', reactionMessage.message);
        setValue('channelId', reactionMessage.channelId);

        setPreviewMessage(reactionMessage.message);
    }

    useEffect(() =>
    {
        // console.log("AdminReactionsMessagePage - useEffect");
        // console.log("params", params);
        // console.log("messageId:", params.messageId);
        // console.log("isNewMessage:", isNewMessage);

        setDebug(params.messageId?.toString() || "");

        GetDiscordChannelsAsync()
            .then((channels) =>
            {
                setChannelsOptions(channels);

                if (isNewMessage)
                {
                    setPageTitle("👀 Create a new reaction message")
                    return;
                }

                setPageTitle("📝 Editing a reaction message")

                LoadReactionsMessageAsync();
            });

        GetRolesAsync();
    }, []);

    return <>
        <div className="global-default-page-container admin-reactions-message">
            MessageId = {debug}, {isNewMessage.toString()}

            <h1>{ pageTitle }</h1>

            <form id="reactionMessageForm">
                <label htmlFor="title">Title</label>
                <p className="subLabel">To recognize this ReactionMessage amongst others ones.</p>
                <input
                    id="title"
                    type="text"

                    {
                        ...register("title")
                    }
                />

                <label htmlFor="channelId">Channel</label>
                <p className="subLabel">Select where to post this ReactionMessage</p>
                <select
                    id="channelId"

                    {
                        ...register("channelId")
                    }
                >
                    {channelsOptions}
                </select>

                <label htmlFor="message">Message</label>
                <p className="subLabel">Enter the message content.</p>
                <textarea
                    id="message"

                    {
                        ...register("message")
                    }

                    onChange=
                    {
                        (event: React.ChangeEvent<HTMLTextAreaElement>) =>
                        {
                            let message = event.target.value;
                            message = message.replaceAll('\n', '<br />');

                            setPreviewMessage(message);
                        }
                    }
                />

                <label>Roles</label>
                <p className="subLabel">Assign an emoji and a role.</p>
                <Button
                    icon={ButtonIcon.PLUS}
                />
                <EmojiPicker />
            </form>

            <Button
                text="Submit"
                type={ ButtonType.DEFAULT }
                // onClick={ handleSubmit((data: any) => { CreateNewMessage(data as SimpleMessage) }) }
            />
        </div>

        <h2>Preview</h2>
        <DiscordPreview message={previewMessage} />

        <BunnyHomeButton />
    </>
}