import './App.scss';

import Profile from './components/ProfileComponent';
import { Routes, Route } from 'react-router-dom';
import IndexPage from './pages/IndexPage';
import AboutPage from './pages/AboutPage';
import LoginPage from './pages/LoginPage';
import SiteRoute from './constants/SiteRoute';
import UserProfile from './models/UserProfile';
import { useEffect, useMemo, useState } from 'react';
import localforage from 'localforage';
import StorageKey from './constants/StorageKey';
import APIHelper from './helpers/APIHelper';
import IndexBunnyMemberPage from './pages/bunnymember/IndexBunnyMemberPage';
import MemberPage from './pages/MemberPage';
import TestPage from './pages/hidden/TestPage';
import EventCalendarPage from './pages/bunnymember/EventCalendarPage';
import React from 'react';
import { AuthContext, AuthContextProps } from './services/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import { BunnyLevel } from './enums/BunnyLevel';
import AdminTestCreateMessagePage from './pages/bunnymember/admin/AdminTestCreateMessagePage';
import AdminReactionsMessagesPage from './pages/bunnymember/admin/AdminReactionsMessagesPage';
import AdminReactionsMessagePage from './pages/bunnymember/admin/AdminReactionsMessagePage';

function App() {
    // console.log("App - Started! ");

    const [userProfile, updateUserProfile] = useState(new UserProfile());

    var authContextValues = new AuthContextProps(userProfile, updateUserProfile);

    const userProfileMemo: AuthContextProps = useMemo(
        () => (authContextValues),
        [userProfile]
    );

    async function AppStartup()
    {
        // console.log("App - AppStartup - IN");
        // console.log("App - AppStartup - userProfile.id:", userProfile);
        // console.log("App - AppStartup - userProfile.bunnyLevelTitle:", userProfile.bunnyLevelTitle);

        // Avoid endless loops
        if (userProfile.bunnyLevelTitle)
            return;

        let savedUserProfile: UserProfile | null =
            await localforage.getItem(StorageKey.UserToken);

        // console.log("AppStartup - savedUserProfile?", savedUserProfile);

        if (!savedUserProfile)
        return;

        // Validate the token
        const token: string = savedUserProfile?.token || '';
        const validatedUserProfile: UserProfile = await APIHelper.ValidateTokenAsync(token);

        updateUserProfile(validatedUserProfile);

        await localforage.setItem(StorageKey.UserToken, validatedUserProfile);
    }

    useEffect(() =>
    {
        // // TODO Remove us
        // console.log("App - useEffect");
        // console.log("App - UserProfile:", userProfile);

    }, [userProfile]); // Only will be called if userProfile changed

    AppStartup();

    return <AuthContext.Provider value={userProfileMemo}>
        <div className="app">
            <header>
                { process.env.NODE_ENV === 'development' ? <span>[App user profile Username: {userProfile.username}. Bunny rank: {userProfile.bunnyLevelTitle}]</span> : "" }
                <br/> <br/>
                {/* <Profile /> */}
            </header>
            <div className="app-content">
                <Routes>
                    <Route path={SiteRoute.Index}  element={<IndexPage />} />
                    <Route path={SiteRoute.About}  element={<AboutPage />} />
                    <Route path={SiteRoute.Login}  element={<LoginPage />} />
                    <Route path={SiteRoute.Member} element={<MemberPage /> } />

                    { /* Bunny Inside */ }
                    <Route path={SiteRoute.BunnyMemberSection}   element={<ProtectedRoute requiredLevel={BunnyLevel.BunnyMember} />}>
                        <Route path={SiteRoute.IndexBunnyMember} element={<IndexBunnyMemberPage /> } />
                        <Route path={SiteRoute.EventCalendar}    element={<EventCalendarPage /> } />

                        <Route path={SiteRoute.AdminSection} element={<ProtectedRoute requiredLevel={BunnyLevel.BunnyAdmin} />}>
                            <Route path={SiteRoute.AdminReactionsMessages} element={<AdminReactionsMessagesPage />} />
                            <Route path={SiteRoute.AdminReactionsMessage} element={<AdminReactionsMessagePage />} />
                            <Route path={SiteRoute.AdminReactionsMessage + "/:messageId"} element={<AdminReactionsMessagePage />} />
                            <Route path={SiteRoute.AdminTestCreateMessage} element={<AdminTestCreateMessagePage />} />
                        </Route>
                    </Route>

                    { /* Hidden */ }
                    <Route path={SiteRoute.Test} element={<TestPage /> } />
                </Routes>
            </div>
        </div>
    </AuthContext.Provider>
}

export default App;
