export default class APIRoute
{
    public static readonly Auth_Login    = "api/Auth/Login/";
    public static readonly Auth_Validate = "api/Auth/Validate/";

    // Job
    public static readonly Job_CreateNewSimpleMessage = "api/Job/CreateNewSimpleMessage";

    // Guild
    public static readonly Guild_GetChannels = "api/Guild/GetChannels";

    // ReactionsMessages
    public static readonly ReactionsMessages_Get = "api/ReactionsMessages/Get";

    // Roles
    public static readonly Roles_Get = "api/Roles/Get";

    // Event
    public static readonly Event_GenerateEventCalendar = "api/Event/GenerateEventCalendar";
}