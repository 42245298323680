import './AdminReactionsMessagesPage.scss';
import React, { useContext, useEffect, useState } from "react";
import BunnyHomeButton from "../../../components/BunnyHomeButtonComponent";
import Button from "../../../components/ButtonComponent";
import { ButtonIcon } from "../../../enums/ButtonIcon";
import { ButtonType } from "../../../enums/ButtonType";
import APIReactionsMessageHelper from "../../../helpers/APIReactionsMessageHelper";
import ReactionsMessage from "../../../models/API/ReactionsMessages/ReactionsMessage";
import { AuthContext, AuthContextProps } from "../../../services/AuthContext";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SiteRoute from '../../../constants/SiteRoute';

async function GetReactionsMessages(navigate: NavigateFunction): Promise<JSX.Element>
{
    let reactionsMessages: ReactionsMessage[] =
        await APIReactionsMessageHelper.GetReactionsMessagesAsync();

    // console.log("reactionsMessages", reactionsMessages);

    let reactionsMessagesDOM = reactionsMessages.map((value: ReactionsMessage, index) =>
    {
        const label = `#${value.channelName} - ${value.title}`;

        return <>
            <div className='buttonRow'>
                <Button
                    text={label}
                    maxCharactersCount={50}
                    onClick={() =>
                    {
                        navigate(
                            SiteRoute.AdminReactionsMessage + "/" + value.messageId,
                            {
                                replace: true
                            });
                    }} />

                <Button type={ButtonType.DISABLED} icon={ButtonIcon.TRASH}/>
            </div>
        </>
    });

    // console.log("reactionsMessagesDOM", reactionsMessages);

    return <>{reactionsMessagesDOM}</>;
}

export default function AdminReactionsMessagesPage()
{
    const navigate: NavigateFunction    = useNavigate();
    const authContext: AuthContextProps = useContext(AuthContext);

    const [reactionsMessages, setReactionsMessages] = useState(<p>...</p>);

    useEffect(() =>
    {
        // console.log("AdminReactionsMessagesPage - useEffect");

        GetReactionsMessages(navigate)
            .then((reactionsMessagesResult: JSX.Element) =>
            {
                setReactionsMessages(reactionsMessagesResult);
            });
    }, []);

    return <>
        <div className="global-default-page-container admin-reactions-messages">
            <h1>✌️ Reactions Messages</h1>

            <Button
                text="Create a new message"
                icon={ButtonIcon.PLUS}
                type={ButtonType.GOOD}
                onClick={() => { navigate(SiteRoute.AdminReactionsMessage, { replace: true }); }}
            />

            <h2>Current messages</h2>
            { reactionsMessages }

            <BunnyHomeButton />
        </div>
    </>
}