import axios, { AxiosRequestConfig } from "axios";
import APIRoute from "../constants/APIRoute";
import EnvironmentVariable from "../constants/EnvironmentVariable";
import DiscordChannel from "../models/API/DiscordChannel";
import SimpleMessage from "../models/API/Job/SimpleMessage";
import BaseAPIHelper from "./BaseAPIHelper";

export default class APIJobHelper extends BaseAPIHelper
{
    public static async CreateNewSimpleMessageAsync(message: SimpleMessage): Promise<void>
    {
        const route: string = this.GetRoute(APIRoute.Job_CreateNewSimpleMessage);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        const createNewSimpleMessageResult: any = await axios.post(route, message, params);

        // console.log("CreateNewSimpleMessageAsync, result:", createNewSimpleMessageResult);
    }

    public static async GetChannelsAsync(): Promise<DiscordChannel[]>
    {
        const route: string = this.GetRoute(APIRoute.Guild_GetChannels);
        const params: AxiosRequestConfig = await this.GetAuthorizationHeadersAsync();

        const apiChannels: any = await axios.get(route, params);

        // console.log("GetChannels, result:", apiChannels);

        return apiChannels.data;
    }
}