import { Link } from "react-router-dom";
import LinkCard from "./LinkCardComponent";
import icon_home from "../assets/bxs-home.svg";
import "./HomeButtonComponent.scss";

export default function HomeButton()
{
    return <div className="home-button">
        <Link to="/"><LinkCard image={icon_home} title="Home" /></Link>
    </div>
}