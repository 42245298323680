import React, { Dispatch, SetStateAction } from "react";
import UserProfile from "../models/UserProfile";

export interface IAuthContext
{
    userProfile: UserProfile,
    updateUserProfile: Dispatch<SetStateAction<UserProfile>>,
    testAlert: Function
}

const initContext: IAuthContext =
{
    userProfile: new UserProfile(),
    updateUserProfile: () => {},
    testAlert: () => {}
}

export class AuthContextProps
{
    userProfile: UserProfile;
    updateUserProfile: Function;

    constructor(userProfile?: UserProfile, updateUserProfile?: Function)
    {
        this.userProfile = userProfile || new UserProfile();
        this.updateUserProfile = updateUserProfile || function() {};
        // this.testAlert = authContextProps?.testAlert || function() {};
    }

    public TestAlert(): void
    {
        alert("Alert from AuthContext");
    }
}

const AuthContext = React.createContext(new AuthContextProps());

export { AuthContext };