import Button from "../components/ButtonComponent";
import EnvironmentVariable from "../constants/EnvironmentVariable";
import { ButtonIcon } from "../enums/ButtonIcon";
import SessionHelper from "../helpers/SessionHelper";
import HomeButton from "../components/HomeButtonComponent";
import { useContext, useState } from "react";
import { AuthContext, AuthContextProps } from "../services/AuthContext";
import UserProfile from "../models/UserProfile";

export default function MemberPage()
{
    const authContext: AuthContextProps = useContext(AuthContext);
    const userProfile: UserProfile = authContext.userProfile;

    function RedirectToDiscordAuthentication()
    {
        const redirectUrl: string = process.env[EnvironmentVariable.DiscordRedirectURI] ?? '';

        window.location.assign(redirectUrl);
    }

    async function DisconnectAsync()
    {
        await SessionHelper.ClearUserProfile();

        window.location.assign('/');
    }

    function ShowAnonymous()
    {
        return <>
        <h1>Hello!</h1>
        <p>If you're a Bunny Hope member, please log in with Discord to access the internal tools 🐰</p>
        <Button
            text="Log in with Discord"
            icon={ButtonIcon.DISCORD}
            onClick={ RedirectToDiscordAuthentication } />
        </>
    }

    function ShowConnected()
    {
        return <>
        <h1>Hello {userProfile.username}! 🐰</h1>
        <p>You're connected via Discord.</p>
        <p>Click on the button below to disconnect from this website.</p>
        <Button text="Disconnect me"
        onClick={ DisconnectAsync } />

        {/* <table className="debugTable">
            <tr>
                <td>id</td> <td> { userProfile.id }</td>
            </tr>
            <tr>
                <td>username</td> <td> { userProfile.username }</td>
            </tr>
            <tr>
                <td>avatar</td> <td> { userProfile.avatar }</td>
            </tr>
            <tr>
                <td>discriminator</td> <td> { userProfile.discriminator }</td>
            </tr>
            <tr>
                <td>publicFlags</td> <td> { userProfile.publicFlags }</td>
            </tr>
            <tr>
                <td>flags</td> <td> { userProfile.flags }</td>
            </tr>
            <tr>
                <td>banner</td> <td> { userProfile.banner }</td>
            </tr>
            <tr>
                <td>bannerColor</td> <td> { userProfile.bannerColor }</td>
            </tr>
            <tr>
                <td>accentColor</td> <td> { userProfile.accentColor }</td>
            </tr>
            <tr>
                <td>locale</td> <td> { userProfile.locale }</td>
            </tr>
            <tr>
                <td>mfaEnabled</td> <td> { userProfile.mfaEnabled }</td>
            </tr>
            <tr>
                <td>premiumType</td> <td> { userProfile.premiumType }</td>
            </tr>
            <tr>
                <td>email</td> <td> { userProfile.email }</td>
            </tr>
            <tr>
                <td>verified</td> <td> { userProfile.verified }</td>
            </tr>
            <tr>
                <td>fullUsername</td> <td> { userProfile.fullUsername }</td>
            </tr>
            <tr>
                <td>bunnyLevel</td> <td> { userProfile.bunnyLevel }</td>
            </tr>
        </table> */}
        </>;
    }

    return <>
    { userProfile.id ? ShowConnected() : ShowAnonymous() }
    <HomeButton />
    </>
}